import * as React from 'react'
import {  Link } from 'gatsby'
import Layout from '../../components/layout'
import '../../styles/global.css' 
import {Helmet} from "react-helmet";

import Slide from 'react-reveal/Slide';


export default function Cop26() {
  return (
    <Layout pageTitle="COP 26">

      <Helmet>
        <meta charset="utf-8"/>
        <meta http-equiv="X-UA-Compatible" content="IE=edge"/>
        <title>COP 26 - USI Projects</title>
        <meta name="description" content="In October 2021, USI were asked by Oliver Jeffers and his team to help deliver the installation of his two sculptures at COP26."/>
        <meta property="og:title" content="Urban Scale Interventions" />
        <meta property="og:type" content="website" />
        <meta property="og:url" content="https://urbanscaleinterventions.com/projects" />
        <meta property="og:image" content="" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0"/>
      </Helmet>

        <section class="text-gray-700 body-font heading-container-cop26">
        <div class="relative mx-auto h-full px-5 md:px-16 py-24 items-center">
          <section class="self-end">
              <div class="absolute bottom-10">
                <h3 class="text-5xl lg:text-8xl font-heading leading-tight text-white pt-16">COP 26</h3>
                <p class="text-white text-xl md:text-2xl lg:mt-0"><span class="font-bold">Client: </span>
                  Oliver Jeffers</p>
              </div>
          </section>
        </div>
      </section>





      <section class="text-gray-700 body-font">
        <div class="container mx-auto flex px-5 pt-12 lg:pt-24 md:flex-row flex-col items-center">
          <section>
            <div class="flex flex-wrap">
              <div class="md:w-3/4 py-4 md:px-32 mb-4 md:mb-0">
                <h3 data-scroll data-scroll-speed="1" class="text-6xl font-heading text-blue-900">OVERVIEW</h3>
                <p data-scroll data-scroll-speed="1" data-scroll-class="scroll-class" data-scroll-repeat="true"
                  class="my-4 text-black leading-relaxed text-xl md:text-2xl lg:text-3xl ">
                  In October 2021, USI were asked by Oliver Jeffers and his team to help deliver the installation of 
                  his two sculptures at COP26; one large, family accessible outdoor work, The Celestial Census, and 
                  one more direct kinetic sculpture, People Live Here. Both pieces point us toward the same request, 
                  to zoom out and consider the simple reality that this earth alone in the cosmos is the only place 
                  where people can and do live. 
                </p>
              </div>
              <div class="md:w-1/4 py-4 md:px-8 mb-4 md:mb-0 md:mt-28">
                <p class="text-2xl font-bold uppercase">Fields of
                  Practice</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">
                  Resilience<br/>
                  Public Space<br/>
                  Culture<br/>
                  Tourism<br/>
                  Technology<br/>
                </p>
                <p class="text-2xl font-bold uppercase mt-8">Services</p>
                <p class="my-4 text-gray-500 leading-relaxed mt-0">
                  Installation</p>
                  <p class="text-2xl font-bold uppercase mt-8">Visit</p>
                <a className="text-xs" href="https://www.oliverjeffers.com/cop26"><p
                  data-scroll-repeat="true" class="my-4 text-gray-500 leading-relaxed mt-0">oliverjeffers.com/cop26
                </p></a>
              </div>
            </div>
          </section>
        </div>
      </section>

      <div class="px-5 md:px-16 py-5 md:py-16">
        <div class="w-3/6 py-4">
          <h1 class="text-2xl md:text-3xl lg:text-4xl font-heading text-blue-900">PROJECT ONE:</h1>
          <h1 class="text-3xl md:text-4xl lg:text-6xl font-heading text-blue-900">THE CELESTIAL CENSUS</h1>
        </div>
        <div class="md:mt-16 md:pt-16 w-full md:flex md:space-x-4 py-4">
        <Slide right>
            <img className="py-2 w-full md:w-2/6" src="https://ik.imagekit.io/5y988hvt8sd/USI/winecellar_lighting_tom_01-a_liKCVJj-1.png?updatedAt=1637169987926" alt="Crown Mural"/>
            <img className="py-2 w-full md:w-2/6" src="https://ik.imagekit.io/5y988hvt8sd/USI/crown-entry_mural_white_a_ui3WbZVn-C.png?updatedAt=1637169987683" alt="Artwork"/>
            <img className="py-2 w-full md:w-2/6" src="https://ik.imagekit.io/5y988hvt8sd/USI/light_and_artwork_without_sign_v997KVWVnH.png?updatedAt=1637169987760" alt="Wine Cellar"/>
        </Slide>
        </div>
        <div class="flex flex-wrap md:p-16 md:m-16 px-5">
          <div class="lg:w-3/6 px-5">
            <img src="https://ik.imagekit.io/5y988hvt8sd/USI/TheCelestialCensus_Cop26Jeffers_8hyul5qYDm.png?updatedAt=1637169799177"/>            
          </div>
          <div class="lg:w-3/6 px-5">
            <p data-scroll data-scroll-speed="2" class="text-xl md:text-3xl ">
              Situated outdoors in the Green Zone, The Celestial Census encompasses two large sculptures 
              representing the Earth and the Moon, showing an accurate scale of their size and distance from one 
              another in the cosmos. Behind the Earth is the declaration that ‘All the People Live Here’ while across 
              space, all the way to the Moon, we are informed “No One Lives Here (or Anywhere Else!). This installation 
              was painted live at COP 26 by Oliver himself.
            </p>
          </div>
        </div>
      </div>

      <div class="px-5 md:px-16 py-5 md:m-16 md:h-4/6">
          <div class="w-full md:flex md:space-x-28">
            <img class="py-2 w-full md:w-2/6" src="https://ik.imagekit.io/5y988hvt8sd/USI/20211103_171206_1_WM6bVPQfJg.png?updatedAt=1637230090239" alt="Earth"/>
            <img class="py-2 w-full md:w-4/6" src="https://ik.imagekit.io/5y988hvt8sd/USI/20211103_170238_1_D7_6Ew7dM.png?updatedAt=1637229718519" alt="Moon"/>
          </div>
      </div>

      <div class="px-5 md:px-16 py-5 md:py-16 md:m-16">
        <div class="w-3/6 py-4">
          <h1 class="text-2xl md:text-3xl lg:text-4xl font-heading text-blue-900">PROJECT TWO:</h1>
          <h1 class="text-3xl md:text-4xl lg:text-6xl font-heading text-blue-900">PEOPLE LIVE HERE</h1>
        </div>
        <div class="flex flex-wrap md:p-16 md:m-16 px-5">
          <div class="lg:w-3/6">
            <p data-scroll data-scroll-speed="2" class="text-xl md:text-3xl ">
              A 3m x 3m dark cube with carefully removed sections shows a large globe of our planet rotating silently 
              against the void of the Universe. A bright sunlike spotlight illuminates it. However, as it 
              rotates around to its 'night' the viewer will catch a glimpse of UV light revealing all of the 
              landmasses we humans have staked out on the habitable dry land of this orb. And on closer inspection, 
              rather than revealing the country names we have given each territory, they will simply say, 
              over and over again ‘People Live Here’.
            </p>
          </div>
          <div class="lg:w-3/6 px-5">
            <img src="https://ik.imagekit.io/5y988hvt8sd/USI/38_cop26_people-live-here__1__AuiL35hDyI.jpeg?updatedAt=1637169799194"/>            
          </div>
        </div>
      </div>

     

      <img class="w-full" src="../img/projects/globes.webp"></img>

      <div class="bg-gray-200 py-16">
        <div class="text-center">
          <h3
            class="mt-2 text-3xl leading-8 font-extrabold tracking-tight sm:text-5xl sm:leading-10 font-heading text-blue-900">
            Explore more work
          </h3>
          <p class="mt-4 max-w-2xl text-xl md:text-2xl  leading-7 text-black mx-12 md:mx-auto pt-6">
            Want to see more? Take a look at the way we’re responding to changes in the way we live, work and
            play.
          </p>
        </div>

        <div class="max-w-screen grid grid-cols-1 md:grid-cols-2 text-center">
          <div class="">
            <Link to="../../projects" class="carrow cta font-heading flipped text-gray-500 text-sm">All Projects
              <span className='hidden sm:block'></span>
            </Link>
          </div>
          <div class="">
            <Link to="../zoo" class="carrow cta font-heading text-gray-600">Next Project
              <span className='hidden sm:block'></span>
            </Link>
          </div>
        </div>
      </div>
    </Layout>
  )
}
